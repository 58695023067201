body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.middle-stuff {
  background-color: #f8f9fa;
  max-width: 1000px; 
	margin: 0 auto !important; 
	float: none !important; 
}

.fa-download:before {
  content: "📥"
}

p.bold.name > a {
  -webkit-text-size-adjust: 100%;
  font-style: normal;
  font-size: 16px;
  font-family: 'Open sans','lucida grande',sans-serif;
  font-weight: 700;
  line-height: 1.5em;
  box-sizing: border-box;
  transition: color .2s;
  color: #000;
  text-decoration: none;
}

.left {
  float: left;
  padding: 0 10px 0 0;
}

.photo-carousel {
  padding: 10px;
}

div.photo-carousel > div > div > div > button {
  display: none;
}